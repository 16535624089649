import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import "../CSS/Testimonial.css";
import DB from "../Images/Doubel Quets.webp";

import KomalSinghal from "../Images/Reviews/Komal Singhal 01.jpeg";
import ManaliShah from "../Images/Reviews/Manali Shah 01.jpeg";
import ReshuMehta from "../Images/Reviews/Reshu Mehta 01.jpeg";
import SagarShah from "../Images/Reviews/Sagar Shah 01.jpeg";
import RinkuAgarwal from "../Images/Reviews/Rinku Agarwal 01.jpeg";
import SwatiAmbani from "../Images/Reviews/Swati Ambani 01.jpeg";
import NehaandPravin from "../Images/Reviews/Neha and Pravin.jpeg";
import ViralShah from "../Images/Reviews/Viral Shah.jpeg";
import Puravshah from "../Images/Reviews/Purav shah.jpeg";
import Footer from '../Component/Footer';


import Bounce from 'react-reveal/Bounce';


const Testimonial = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <>

      {/* /////////////////////// SEO ////////////////////////// */}

      <Helmet>
        <meta name='description' content='Mahip Agarwal is one of the best vastu expert in Mumbai. With more the 10 years as vastu consultant he helped lot of his clients achieve success. Call Now!' />
        <meta name="title" content="Best Vastu Expert in Mumbai | By Mahip Agarwal  Consult Now!" />
        <meta name="keywords" content="best vastu consultant,vastu consultant,vastu shastra consultant,vastu expert in mumbai,vastu consultant in mumbai,best vastu consultant in mumbai,best vastu expert in Mumbai" />
        {/* <!-- Open Graph --> */}
        <meta property="og:url" content="http://divineastrovastu.in/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Divine Astro Vastu" />

        <link rel="canonical" href="http://divineastrovastu.in/ " />
      </Helmet>

      {/* /////////////////////// SEO ////////////////////////// */}


      <section className='Testimonial-Section-1'>
        <div className='Testimonial-text'>
          <h1>

            <Bounce left cascade>
              What Our Client Says     
            </Bounce>


          </h1>
        </div>
        <div className='container'>
          <div className='Testimonial'>
            <div className='Testimonial-con-1'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='card-body'>
                    <div className='Card-Body-Con'>
                      <div className='Card-Body'>
                        <div className='Card-Image-Con'>
                          <div className='Card-Image'>
                            <img src={DB} alt="" />
                          </div>
                        </div>
                        <p>
                          The man in general public just like any of us but he has been studying this subject of astrology since years.. he is an expert in field of vastu and astrology... If you think you need some small solutions to your problems then he is your person...if you need some big changes in your life ...he can guide you vastu wise as well as astrology wise...he is very knowledgeable in field of speaking nature of human through kundali.. you get to know even know your own self through kundali and make changes in whatever way is required as per your suitability and circumstance....do try him for amazing results
                        </p>
                        <div className='Card-testimonial-client'>
                          <div className='Card-Client-Image-con'>
                            <div className='Card-Client-Image'>
                              <img src={KomalSinghal} alt="Komal Singhal" />
                            </div>
                            <div className='Client-Details'>
                              <h4>Komal Singhal</h4>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ///////// */}

                <div className='col-md-6'>
                  <div className='Card-Body-Con'>
                    <div className='Card-Body'>
                      <div className='Card-Image-Con'>
                        <div className='Card-Image'>
                          <img src={DB} alt="" />
                        </div>
                      </div>
                      <p>
                        Hellooo everyone his vastu is very accurate in my case it’s 10 /10 n his way of telling to people is very good n in positive way he greets people coming to vastu n kundli it’s very effective n perfect
                      </p>
                      <div className='Card-testimonial-client'>
                        <div className='Card-Client-Image-con'>
                          <div className='Card-Client-Image'>
                            <img src={ManaliShah} alt="Manali Shah" />
                          </div>
                          <div className='Client-Details'>
                            <h4>Manali Shah</h4>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* ///////// */}

                <div className='col-md-6'>
                  <div className='Card-Body-Con'>
                    <div className='Card-Body'>
                      <div className='Card-Image-Con'>
                        <div className='Card-Image'>
                          <img src={DB} alt="" />
                        </div>
                      </div>
                      <p>
                        I am very much impressed with his deep and thorough knowledge in Astrology and vastu. He is very expert in his subject matter and very patient listener. He is able to convey past ,present and future with accuracy. It is amazing. Moreover he suggests remedies which are very simple and very effective. I personally experienced the impact of his remedies in my life as well as my family's life and got benefitted with those. I strongly recommend him and wish him all success in his life and moreover I appreciate his noble approach to help people.
                      </p>
                      <div className='Card-testimonial-client'>
                        <div className='Card-Client-Image-con'>
                          <div className='Card-Client-Image'>
                            <img src={ReshuMehta} alt="Reshu Mehta" />
                          </div>
                          <div className='Client-Details'>
                            <h4>Reshu Mehta</h4>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* ///////// */}

                <div className='col-md-6'>
                  <div className='Card-Body-Con'>
                    <div className='Card-Body'>
                      <div className='Card-Image-Con'>
                        <div className='Card-Image'>
                          <img src={DB} alt="" />
                        </div>
                      </div>
                      <p>
                        Thank you Mahip Agarwalji for helping me grow my business with the best vastu tips you gave me. Your terrific and in-dept knowledge came in as a blessings. Your calculations are impeccable. Your easy to do remedies helped us to decode the exact root of the problem. As a result you have the ability to suggest solutions with pin pointed accuracy.
                      </p>
                      <div className='Card-testimonial-client'>
                        <div className='Card-Client-Image-con'>
                          <div className='Card-Client-Image'>
                            <img src={SagarShah} alt="Sagar Shah" />
                          </div>
                          <div className='Client-Details'>
                            <h4>Sagar Shah</h4>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* ///////// */}

                <div className='col-md-6'>
                  <div className='Card-Body-Con'>
                    <div className='Card-Body'>
                      <div className='Card-Image-Con'>
                        <div className='Card-Image'>
                          <img src={DB} alt="" />
                        </div>
                      </div>
                      <p>
                        I had health concerns for 10 years, and the rest other things were very good in life. Went to almost 20+ doctors across the country and no one was able to detect the reason. The pain and issues used to come only during sleeping hours and for years I didn’t sleep properly, Mahip Agarwal helped us by making few changes with no major expenses which made my health issues go and I could sleep properly. He is amazing, doesn’t scare you but shows simple solutions which are very effective”
                      </p>
                      <div className='Card-testimonial-client'>
                        <div className='Card-Client-Image-con'>
                          <div className='Card-Client-Image'>
                            <img src={RinkuAgarwal} alt="Rinku Agarwal" />
                          </div>
                          <div className='Client-Details'>
                            <h4>Rinku Agarwal</h4>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* ///////// */}

                <div className='col-md-6'>
                  <div className='Card-Body-Con'>
                    <div className='Card-Body'>
                      <div className='Card-Image-Con'>
                        <div className='Card-Image'>
                          <img src={DB} alt="" />
                        </div>
                      </div>
                      <p>
                        Thank you Mahip bhai for demonstrating a high a degree of knowledge and analysing the situations and further forecasting the effects in my career.
                      </p>
                      <div className='Card-testimonial-client'>
                        <div className='Card-Client-Image-con'>
                          <div className='Card-Client-Image'>
                            <img src={SwatiAmbani} alt="Swati Ambani" />
                          </div>
                          <div className='Client-Details'>
                            <h4>Swati Ambani</h4>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* ///////// */}

                <div className='col-md-6'>
                  <div className='Card-Body-Con'>
                    <div className='Card-Body'>
                      <div className='Card-Image-Con'>
                        <div className='Card-Image'>
                          <img src={DB} alt="" />
                        </div>
                      </div>
                      <p>
                        One can never know how Vastu can impact their life untill you consult a vastu expert..
                        This is one of the initmate detail to share with anyone and so we needed someone we could trust on..
                        And thats where exactly we met Mahip and there cant be anyone else we could trust this much.
                        I think so trust is most important thing when you want any change in your life.
                        Mahip is patient and thorough with his process.
                        He is calm and good listener. He understands the problems with depth and studies it and has most suitable solutions for the issue.
                        He looks at the issues from multiple angles, considers unconventional inputs and leave no stone unturned to solve them.
                        We thank him for his selfless, friendly and accommodative approach to our issues. We wish him best of success and bringing smiles in countless families he would be consulting in future.
                      </p>
                      <div className='Card-testimonial-client'>
                        <div className='Card-Client-Image-con'>
                          <div className='Card-Client-Image'>
                            <img src={NehaandPravin} alt="Neha and Pravin" />
                          </div>
                          <div className='Client-Details'>
                            <h4>Neha and Pravin</h4>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* ///////// */}

                <div className='col-md-6'>
                  <div className='Card-Body-Con'>
                    <div className='Card-Body'>
                      <div className='Card-Image-Con'>
                        <div className='Card-Image'>
                          <img src={DB} alt="" />
                        </div>
                      </div>
                      <p>
                        I have been consulting Mahip Bhai since last many years regarding any professional or personal decisions to be taken and he has guided me to take the best decisions. I have seen immediate results after applying the remedies suggested by him. The remedies suggested by him are very easy to implement and very effective. I trust him a lot and before taking any important decision of my life, I consult him first. I highly recommend him as one of the best Astrologer and Vastu Consultant.
                      </p>
                      <div className='Card-testimonial-client'>
                        <div className='Card-Client-Image-con'>
                          <div className='Card-Client-Image'>
                            <img src={ViralShah} alt="Viral Shah" />
                          </div>
                          <div className='Client-Details'>
                            <h4>Viral Shah</h4>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* ///////// */}

                <div className='col-md-6'>
                  <div className='Card-Body-Con'>
                    <div className='Card-Body'>
                      <div className='Card-Image-Con'>
                        <div className='Card-Image'>
                          <img src={DB} alt="" />
                        </div>
                      </div>
                      <p>
                        I am really happy with the results and with the way he explains and his remedies are of minimal cost. I would recommend him 100% for any kind of issues in life. 😊
                      </p>
                      <div className='Card-testimonial-client'>
                        <div className='Card-Client-Image-con'>
                          <div className='Card-Client-Image'>
                            <img src={Puravshah} alt="Purav shah" />
                          </div>
                          <div className='Client-Details'>
                            <h4>Purav shah</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Testimonial