import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import "../CSS/myths.css";
import vastuLogo from "../Images/vasto logo.png";
import Footer from "../Component/Footer";

import Bounce from 'react-reveal/Bounce';


const Myths = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <>


      {/* /////////////////////// SEO ////////////////////////// */}

      <Helmet>
        <meta name='description' content='Mahip Agarwal is one of the best vastu expert in Mumbai. With more the 10 years as vastu consultant he helped lot of his clients achieve success. Call Now!' />
        <meta name="title" content="Best Vastu Expert in Mumbai | By Mahip Agarwal  Consult Now!" />
        <meta name="keywords" content="best vastu consultant,vastu consultant,vastu shastra consultant,vastu expert in mumbai,vastu consultant in mumbai,best vastu consultant in mumbai,best vastu expert in Mumbai" />
        {/* <!-- Open Graph --> */}
        <meta property="og:url" content="http://divineastrovastu.in/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Divine Astro Vastu" />

        <link rel="canonical" href="http://divineastrovastu.in/ " />
      </Helmet>

      {/* /////////////////////// SEO ////////////////////////// */}


      {/* ////////////// Section /////////////// */}


      <section className='Myths-Section-1'>
        <div className='Myths-Text'>
          <h1>
          <Bounce left cascade>
           
          Myths about Vastu

            </Bounce>

            
          </h1>
        </div>
        <div className='container'>
          <div className='Myths-Section-container'>

            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="best vastu consultant" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>1.	Putting idol or picture of Lord Ganesha on the entrance door is auspicious</h3>
                    <p>It is a general practice amongst the Indian households to place Lord Ganesha's idol or photo at the entrances of their homes or offices. By doing so, i.e by placing a figurine of Lord Ganesha at the entrance, the status of Lord Ganesha is reduced to that of a gatekeeper (i.e dwarpal). Since it is a common belief that it is inauspicious to place a figurine of Lord Ganesha with his back towards home, so the people started keeping another image/ idol of Lord Ganesha on the inside of their entrances. This practice needs to be avoided at any cost since it brings poverty.</p>
                  </div>
                </div>
              </div>
            </div>


            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="vastu consultant" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>2.	The entrance door of the house /office should be facing North East</h3>
                    <p>Many people believe that only North East facing entrance is auspicious and hence they don't go for plots facing South or West. According to Vastu, the direction in which a building faces doesn't matter, what is important from Vastu point of view is the exact location of the entrance. One should choose the direction of the entrance depending upon the areas they want to excel in life and then take the decision as to which entrance would be the best for their growth and excellence. For knowledge, skills and expertise, West direction is most suitable and for fame, recognition and power, South direction is the best. </p>
                  </div>
                </div>
              </div>
            </div>


            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="vastu shastra consultant" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>3.	Toilet at the entrance is inauspicious</h3>
                    <p>It's a common myth that a toilet at the entrance is inauspicious. But the fact is the effects of entrance on the residents is different from that of a toilet. The entrance has its own independent effects, irrespective of the location of the toilet. According to Vastu, the ideal zone for a toilet is the zone of disposal, whether that zone lies close to the entrance or somewhere else.</p>
                  </div>
                </div>
              </div>
            </div>


            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="vastu expert in mumbai" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>4.	Toilet and kitchen should not be together</h3>
                    <p>It is a common myth that a toilet should not be adjacent to the kitchen and hence toilet adjacent to kitchen is believed to be inauspicious. To the contrary of this general belief, according to Vastu Shastra, the ideal location for Kitchen and Toilet are adjacent to each other. According to Vastu Shastra, the ideal zone for kitchen is South East and other permitted zones for kitchen are North- West, West, South and SSE Zone. The zones permissible for the location of the toilet according to Vastu Shastra are SSW, WNW and ESE. Kitchen is represented by fire and toilet is represented by the earth element, there is no Vastu Dosh if the toilet is adjacent to kitchen, since fire complements earth element.</p>
                  </div>
                </div>
              </div>
            </div>


            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="vastu consultant in mumbai" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>5.	Worship place/ temple is ideal in North East</h3>
                    <p>A generally followed myth is that any kind of 'Puja' should be done in the North East Zone only. But if we carefully observe, in most of the temples, deities have been placed in the West Zone, facing the East direction. According to Vastu Shastra, Devta resides in the North East Zone, but it  is not written that one must do Puja in this zone only. The Devta or any potential deity should be placed in this zone but it is not necessary to perform the rituals in this zone only. For the performance of rituals, any adjoining zone i.e. NNE or ENE can be chosen . Different deities who govern various aspects of our lives preside in different directions. So, Puja must be performed in the specific zone depending upon the deity being worshipped. For e.g. puja of Lord Hanuman or Durga Maa should be performed in the South Zone.</p>
                  </div>
                </div>
              </div>
            </div>


            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="best vastu consultant in mumbai" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>6.	Ishaan Koan  or the Brahmasthan should always be kept empty</h3>
                    <p>It is a common belief that the Ishaan koan i.e. the North East zone be left empty. The fact is that the North East Zone is the zone of creativity, meditation, new thoughts and new ideas. This zone should not be used for unproductive work like for storage space, where the articles put once won't be used for months. If the North East Zone is not used judiciously, then we are allowing the creative energy to be wasted. Similarly, if in the South West Zone, too many heavy articles are stored, this zone loses its capital to impart skills in the lives of the residents of the house. The North East Zone can be used as a drawing room, meditation room, study room as well as living room.</p>
                  </div>
                </div>
              </div>
            </div>


            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="best vastu expert in Mumbai" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>7.	Square plots are auspicious</h3>
                    <p>It is a common belief that for constructing a house, square plots are ideal or auspicious. But according to Vastu Shastra only meditation centres or religious places should be built on square shaped plots , while such plots are not ideal for homes and businesses. It is advisable to select a rectangular plot for the construction of your house or business.  </p>
                  </div>
                </div>
              </div>
            </div>


            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="best vastu" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>8.	 Aquarium in the house helps to attract wealth</h3>
                    <p>It is a general myth that if we put an aquarium in the house, then it will help to attract wealth. But the truth is, the aquarium gives the members a feeling of content with whatever they have. The members of the house feel contented with whatever they are getting and they don't try to explore or get deeper into their areas of interest.  Aquarium in the house can curb the analytical powers of the members. It is better not to keep any aquarium in the house, but if you already have one or can't avoid it, then the best direction for the aquarium is East - South - East direction.</p>
                  </div>
                </div>
              </div>
            </div>


            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="vastu for career" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>9.	Pyramids placed anywhere in the building will prove fruitful </h3>
                    <p>It is a common myth that if Pyramids are placed in a building, it proves to be fruitful. People place Pyramids on doors, ceilings or sometimes on the walls of their house or offices. The fact is that Pyramids give results only when they are placed on the floor in the permitted zones.</p>
                  </div>
                </div>
              </div>
            </div>


            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="vastu for Home" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>10.	Cooking should be done facing towards the East</h3>
                    <p>According to Vastu Shastra, it is more important to take care of the zone in which the gas stove is placed, rather than the direction of facing during cooking. The ideal zone for putting the gas stove is the West or South zone of the house. If the kitchen is located in the West zone and the food is cooked facing West, then the cook is able to make best use of his/ her culinary skills. If the kitchen is located in the West zone, it helps the members of the house to grow in their career and they also tend to learn fast from their mistakes.</p>
                  </div>
                </div>
              </div>
            </div>


            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="vastu for relationship" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>11.	The bedroom should not be below or above the kitchen</h3>
                    <p>It is a myth that the location of the bedroom should not be below or above the kitchen.  The people believe that the fire which is ignited in the kitchen will bring discomfort in the lives of the people who reside below or above the kitchen area. The reason behind this belief is that the fire ignited in the kitchen does not allow the body to undertake its regular activities of rejuvenation. But according to Vastu Shastra, there is no such impact of the kitchen on the residents of the house. The kitchen and the bedrooms should be located according to the different attributes of various zones and the purpose of the structure created.</p>
                  </div>
                </div>
              </div>
            </div>


            <div className='Myths-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <div className='Myths-Logos-con'>
                    <img src={vastuLogo} alt="vastu for flats" />
                  </div>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='Myths-Detials'>
                    <h3>12.	Crystals placed in the building are always auspicious</h3>
                    <p>It is a common belief that if crystals are placed in the building, it will bring growth and prosperity. But the fact is that crystals are not always auspicious. Depending upon the shape and size of crystals, different effects can be created by crystals. And if too many crystals are placed in any building, it can create problems. Hence the use of crystals is not always profitable or suitable for the residents. If crystals are placed without proper knowledge, it can create a negative or lethargic environment. There should be minimal use of crystals and that too after having proper knowledge.</p>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Myths